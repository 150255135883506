import React, { useState, useEffect } from 'react';
import './App.css';

import { fetchGoogleSheetsData } from 'google-sheets-mapper';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import axiosInstance from './axios';

const getData = async () => {
  try {
    return await fetchGoogleSheetsData({
      apiKey: "AIzaSyAcmD4PVHSjKAvEM3XZaI0pK1gzGbhuNjk",
      sheetId: "1eh1XBJWSg_Bv7GuYKS5rKJ87eERAe0gk-DC5jONtGDA",
      sheetsOptions: [{ id: 'Leaderboard' }],
    });
  } catch (error) {
    console.error(error);
  }
};

function App() {
  const [data, setData] = useState([]);
  const [refreshTime, setRefreshTime] = useState("");
  const [threshold, setThreshold] = useState(0);
  const [minThreshold, setMinThreshold] = useState(-1);
  const [epa, setEpa] = useState(-1);
  const [worldRank, setWorldRank] = useState(-1);
  const [stateRank, setStateRank] = useState(-1);

  function refreshData(){
    getData().then(newData => {
      setData(newData[0].data); 
      if(newData[0].data.length > 0){
        setThreshold((newData[0].data[0] as any).Threshold);
        var minT = -1;
        for(var i = 0; i < newData[0].data.length; i++){
          if((newData[0].data[i] as any).Points - (newData[0].data[0] as any).Threshold + 1 < 1){
            minT = i;
            break;
          }
        }
        if(minT === -1){
          minT = newData[0].data.length;
        }
        setMinThreshold(minT);
      }
      setRefreshTime(Date().toLocaleString().slice(0, 24));
    });
  }

  function getStatboticsData() {
    axiosInstance.get("/team_year/4944/2024")
      .then(response => {
        var details = response.data.epa;
        setEpa(details.breakdown.total_points.mean);
        setWorldRank(details.ranks.total.rank);
        setStateRank(details.ranks.state.rank);
      })
  }

  useEffect(()=> {
    refreshData();
    getStatboticsData();
    const interval = setInterval(refreshData, 60000);
    return () => {
      clearInterval(interval);
    }
  }, []);

 return (
    <div className="App">
      <header><h1>Hi Fives Leaderboard</h1></header>
      {data.length !== 0 && <div className="leaderboard">
        {
          data.map((row, index) => {
            return(
              <div key={index} className="leaderboard-item-container">
                {index === minThreshold && index === 0 &&
                <div className="divider"></div>}
                <div className="leaderboard-item">
                    <p className="leaderboard-place">{row.Rank}</p>
                    <p className="leaderboard-name">{row.Student}</p>
                    <p className="leaderboard-points">{row.Points} points</p>
                </div>
                {index === minThreshold-1 &&
                <div className="divider"></div>}
              </div>
            )
          })
        }
      </div>}
      <h1>Statbotics Rankings:</h1>
      <div className="epa-item-container">
        <div className="epa-item">
          <p className="epa-item-title">EPA</p>
          <p className="epa-item-data">{epa == -1 ? "loading":epa}</p>
        </div>
        <div className="epa-item">
          <p className="epa-item-title">World Ranking</p>
          <p className="epa-item-data">{worldRank == -1 ? "loading":worldRank}</p>
        </div>
        <div className="epa-item">
          <p className="epa-item-title">State Ranking</p>
          <p className="epa-item-data">{stateRank == -1 ? "loading":stateRank}</p>
        </div>
      </div>
      <div style={{padding: 20}}>
        <iframe width="560" height="315" src="https://www.youtube.com/embed/qQzdAsjWGPg?si=us_R1cMHKiDBr1Cj" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      </div>
      <div>Threshold: {threshold} points | Last refreshed: {refreshTime}</div>
    </div>
  );
}

export default App;
