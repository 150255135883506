import axios from 'axios';

var axiosInstance = axios.create({
  baseURL: 'https://api.statbotics.io/v3',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json'
  }
});

export default axiosInstance;
